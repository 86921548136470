import React, {useContext} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import PrivateRoute from "./middleware/privateRoute";
import PublicRoute from "./middleware/publicRoute";
import CreateProduct from "../pages/manager/products/create";
import ProductsList from "../pages/manager/products/list";
import CreateOrder from "../pages/manager/orders/create";
import NoAccess from "../pages/user/noaccess";
import Dashboard from "../pages/dashboard/home";
import OrderList from "../pages/manager/orders/list";
import EditOrder from "../pages/manager/orders/edit";
import OrderPDF from "../pages/manager/orders/pdf/orderPDF";
import PageNotFound from "../pages/404";
import {Switch} from "react-router";
import UserContext from "../context/userContext";
import StoresIndex from "../pages/manager/stores/stores_index";
import SingleStore from "../pages/manager/stores/single_store";
import EditProduct from "../pages/manager/products/edit";
import LoginForm from "../pages/user/loginForm";
import RestrictedToAdminRoute from "./middleware/restrictedToAdminRoute";
import FacturePDF from "../pages/manager/orders/pdf/facturePDF";



export const routesName = {
    dashboard : {
        index : "/"
    },
    products : {
        index: "/products",
        create: "/products/create",
        edit: (id) => `/edit/${id}`
    },
    orders : {
        index : {
            path : "/orders/list",
            component : OrderList
        },
        create : "/orders/nouveau",
        edit : "/orders/edit/:id",
        pdf : {
            path : (id) => `/orders/pdf/${id}`,
            component : OrderPDF
        },
        facture : {
            path : (id) => `/orders/facture/${id}`,
            component : FacturePDF
        }
    },
    stores : {
        index : {
            path : `/stores`,
            component : <StoresIndex/>
        },
        create : "/stores/create",
        single : {
            path : (id) => `/stores/${id}`,
            component : <SingleStore/>
        }
    }
};
function SghiriRouter() {
    const {isAuth} = useContext(UserContext);
    return (
            <Router>
                <Switch>
                    {/*Manager Dashboard*/}
                    <PrivateRoute isAuth={isAuth} component={Dashboard} path={routesName.dashboard.index} exact/>

                    {/*Manager Products*/}
                    <RestrictedToAdminRoute component={ProductsList} exact path={routesName.products.index}/>
                    <RestrictedToAdminRoute isAuth={isAuth} component={CreateProduct} path={routesName.products.create}/>
                    <RestrictedToAdminRoute isAuth={isAuth} component={EditProduct} path={routesName.products.edit(":id")}/>

                    {/*Manager Orders*/}

                    {/*<RestrictedToAdminRoute userRole={userRole} isAuth={isAuth} component={OrderIndex} path={routesName.orders.index}/>*/}
                    <PrivateRoute  component={routesName.orders.index.component} path={routesName.orders.index.path}/>
                    <PrivateRoute  component={EditOrder} path={routesName.orders.edit}/>
                    <PrivateRoute  component={CreateOrder} path={routesName.orders.create}/>
                    <PrivateRoute  component={routesName.orders.pdf.component} withLayout={false} path={routesName.orders.pdf.path(':id')}/>
                    <PrivateRoute  component={routesName.orders.facture.component} withLayout={false} path={routesName.orders.facture.path(':id')}/>
                    {/*Manager Stores*/}
                    <PrivateRoute  component={StoresIndex}  path={routesName.stores.index.path} exact />
                    <PrivateRoute  component={SingleStore}  path={routesName.stores.single.path(':id')}/>

                    {/*Login Page*/}
                    <PublicRoute restricted={true}  component={LoginForm} path={"/login"}/>
                    <PublicRoute restricted={false}  component={NoAccess} path={"/no_access"}/>
                    <PublicRoute restricted={false}  component={PageNotFound} path={"*"}/>
                    <PublicRoute restricted={false}  component={PageNotFound} path={"/404"}/>
                    <PublicRoute restricted={false}  component={<p></p>} path={"/temp"}/>
                </Switch>
            </Router>
    );
}

export default SghiriRouter;
