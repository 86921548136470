import React, {createRef} from "react";
import {Autocomplete, FormHelperText, Grid, Paper, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import AddBoxIcon from "@mui/icons-material/AddBox";


export default function Items({formik,currentQte,products,setCurrentQte,items,setItems,currentProduct,setCurrentProduct,itemsError}){
    const autoC = createRef(null);
    async function handleItems (){
        const newItem = {
            product_id : currentProduct.product_id,
            product : currentProduct,
            quantity: parseInt(currentQte)
        }
        if(items === null) {
            setItems([newItem])
            // formik.setFieldValue('items', [newItem,newItem]);
        } else {
            const find = items.find((i) => i.product.id === newItem.product.id);
            if(find) {
            } else {
                setItems((prev) => [
                    ...prev,
                    newItem
                ]);

            }
        }

    }
    return(
        <Paper sx={{width: "100%", padding: 2}}>
            <Grid container item spacing={2} xs={12}>
                <Grid container item xs={12}>
                    <Typography fontWeight={"bold"}>Produits :</Typography>
                </Grid>
                <Grid container item xs={2}>
                    <TextField
                        fullWidth
                        label="QTE"
                        value={currentQte}
                        variant="outlined"
                        onChange={(e) => e.target.value === null ? setCurrentQte(0) : setCurrentQte(e.target.value)}
                    />
                </Grid>
                {products &&
                    <Grid container item xs={8}>
                        <Autocomplete
                            disabled={currentQte < 1 && true}
                            fullWidth
                            id="selectProduct"
                            options={products}
                            ref={autoC}
                            getOptionLabel={o => o.name}
                            renderOption={(props,option) =>(
                                <Grid key={`product-${option.id}`} container item xs={12} {...props}>
                                    <Grid container item xs={8}>
                                        {option.name}
                                    </Grid>
                                    <Grid container item justifyContent="center" xs={4}>
                                        <Typography sx={{ color : (option.quantity - option.sales) >= 20 ? "green" : "red" }}>Disponible : {option.quantity - option.sales}</Typography>
                                    </Grid>
                                </Grid>
                            )}

                            getOptionDisabled={(option) =>
                                (option.quantity - option.sales === 0)
                                ||
                                (currentQte > option.quantity - option.sales)
                                ||
                                (items && items.find((i) => i.product_id === option.product_id ) !== undefined)
                            }
                            onChange={(e,v) => v && setCurrentProduct(v)}
                            renderInput={(params) => <TextField  {...params} label="Produit" />}
                        />
                    </Grid>


                }
                <Grid container item alignItems="center" xs={2}>
                    <AddBoxIcon
                        sx={{fontSize : 35,cursor : "pointer", "&:hover" : { color : (theme) => (theme.palette.primary.main)}}}
                        onClick={  () => {
                            handleItems();
                            const ele =  autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                            if (ele) ele.click();
                        }}
                    />
                </Grid>
            </Grid>
            {itemsError && <FormHelperText error={itemsError}>veuillez selectionez au moin un produit</FormHelperText>}
        </Paper>

    )
}