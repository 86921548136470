import React, {useContext, useEffect, useState} from "react";
import apiCall from "../../../api/axios_config";
import UserContext from "../../../context/userContext";
import {FormControlLabel, Grid, Switch, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useFormik} from "formik";
import * as yup from "yup";
import axios from "axios";
import PickUp from "./create_form/store";
import Specification from "./create_form/specification";
import ClientInformation from "./create_form/clientInformation";
import Items from "./create_form/items";
import Itemslist from "./create_form/itemslist";
import DiscountSection from "./create_form/discount";
import OldClient from "./create_form/oldclient";
import {calculateTotal} from "../../../helpers/functions";
import AlertsContext from "../../../context/alertsContext";
import {useHistory} from "react-router-dom";
import Loader from "../../../component/common/loader";
import moment from "moment";
import Stack from "@mui/material/Stack";
import {routesName} from "../../../router/router";



export default function CreateOrder() {
    const [stores, setStores] = useState({});
    const [govs, setGovs] = useState(null);
    const [products, setProducts] = useState({});
    const [activeGov, setActiveGov] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentQte, setCurrentQte] = useState(1);
    const [currentProduct, setCurrentProduct] = useState("");
    const [deliveryCompanies, setDeliveryCompanies] = useState({});
    const [items, setItems] = useState(null);
    const [itemsError, setItemsError] = useState(false);
    const [addNew, setAddNew] = useState(true);
    const [activeStore,setActiveStore] = useState(1);

    const [clientList, setClientList] = useState(null);
    const [oldClient, setOldClient] = useState(null);

    const {accessToken,userInfos} = useContext(UserContext);
    const {setProgressBar,setSnackBar} = useContext(AlertsContext);

    const history = useHistory();

    function updateOldClient(data){
        setOldClient(data);
        formik.setFieldValue('client.deleg_id',data.deleg_id)
        formik.setFieldValue('client.fullName',data.fullName)
        formik.setFieldValue('client.phone',data.phone)
        formik.setFieldValue('client.phoneTwo',data.phoneTwo)
        formik.setFieldValue('client.address',data.address)
        formik.setFieldValue('client.mf',data.mf)

    }
    function deleteOldClient(){
        setOldClient(null);
        formik.setFieldValue('client.deleg_id',"");
        formik.setFieldValue('client.fullName',"");
        formik.setFieldValue('client.phone',"");
        formik.setFieldValue('client.phoneTwo',"");
        formik.setFieldValue('client.address',"");
    }

    function handleStoreChange(value) {
        setActiveGov(value);
        formik.setFieldValue('order.store_id',value);
        apiCall(accessToken).get('/stores/get_stock/' + value).then((res) => setProducts(res.data.stock) );
    }
    const validationSchema = yup.object({
        client:
            yup.object().shape({
                    fullName: yup.string().required("le nom et prénom du client est obligatoire"),
                    phone: yup.number("le numéro de télephone doivent être un numéro").required("Télephone obligatoire"),
                    phoneTwo: yup.number("le numéro de télephone doivent être un numéro"),
                    address: yup.string().required('addresse est obligatioire'),
                    deleg_id: yup.number().required("delegation est obligatoire"),
                }
            ),
        order:
            yup.object().shape({
                store_id: yup.number().required(),
                // discount : yup.number().required(),
                // packageSize: yup.number().required(),
                // paymentStatus: yup.boolean().required(),
                // amount: yup.number().required(),
                // descProduit: yup.string().required(),
                // isExchange: yup.string().required(),
                // isFragile: yup.boolean().required(),
                // freeShipping: yup.boolean().required(),
                // deliveryCompany : yup.object().required(),
            })

    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            order: {
                pickUpDate: moment().format('DD-MM-Y'),
                store_id: userInfos.store.id ? userInfos.store.id : 1,
                discount: 0,
                packageSize: 1,
                paymentStatus: 2,
                amount: "0",
                descProduit: "Jeux Educatifs",
                isExchange: false,
                isFragile: 1,
                freeShipping: 0,
                deliveryCompany: deliveryCompanies[0]
            },
            client: {
                clientId: null,
                fullName: "",
                phone: "",
                phoneTwo: "",
                address: "",
                deleg_id: "",
                mf : ""
            },
            items: []
        },
        onSubmit: (values,{setFieldError}) => {
            setProgressBar(true);
            if (items) {
                var data = new FormData();
                if (oldClient) {
                    data.append('client[clientId]', values.client.clientId)
                } else {
                    data.append('client[fullName]', values.client.fullName);
                    data.append('client[deleg_id]', values.client.deleg_id);
                    data.append('client[address]', values.client.address);
                    data.append('client[phone]', values.client.phone);
                    data.append('client[phoneTwo]', values.client.phoneTwo);
                    data.append('client[mf]', values.client.mf);
                }
                data.append('order[deliveryDate]', values.order.pickUpDate);
                data.append('order[discount]', values.order.discount);
                data.append('order[store_id]', values.order.store_id);
                data.append('order[packageSize]', values.order.packageSize);
                data.append('order[paymentStatus]', values.order.paymentStatus);
                data.append('order[descProduit]', values.order.descProduit);
                data.append('order[free_delivery]', values.order.freeShipping);
                data.append('order[amount]', values.order.paymentStatus === 2 ?
                    calculateTotal(items,values.order.discount,values.order.freeShipping === 1 ? 0 : values.order.deliveryCompany.public_price)
                    :
                    formik.values.order.freeShipping === 1 ? "0.000" : values.order.deliveryCompany.public_price
                );
                data.append('order[delivery_price]', values.order.deliveryCompany.public_price);
                data.append('order[isExchange]',values.order.isExchange ? 1 : 0);

                items.forEach((item, i) => {
                    data.append(`items[${i}][product_id]`, item.product_id);
                    data.append(`items[${i}][quantity]`, item.quantity);
                });
                apiCall(accessToken).post('/orders/storeOrder', data)
                    .then(res => {
                         setSnackBar({
                             status : true,
                             message : "commande passée avec succès",
                             severity : "success"
                         });
                    })
                    .catch((error) => {

                        if (error.response.status === 400) {
                            if(error.response.data["client.phone"]){
                                setFieldError('client.phone',"le téléphone client est déjà utilisé pour un autre client");
                            }
                        }
                        setSnackBar({
                            status : true,
                            message : "Quelque chose s'est mal passé, veuillez réessayer plus tard",
                            severity : "error"
                        })
                    }).finally(() => {
                    setProgressBar(false);
                    if(addNew) {
                        history.push('/temp');
                        history.goBack();
                    } else {
                        history.push(routesName.orders.index.path);

                    }
                })
                ;
            } else {
                setItemsError(true);
                setProgressBar(false);

            }

        }
    });

    useEffect(() => {

        const getStores = apiCall(accessToken).get("/stores/all");
        const getGovs = apiCall(accessToken).get('/places/govs/all?withDeleg=true');
        // const getProducts = apiCall(accessToken).get('/products/all');
        const getDeliveryCompanies = apiCall(accessToken).get('/delivery_companies/all');
        const getStoreStock = apiCall(accessToken).get('/stores/get_stock/' + userInfos.store.id);
        axios.all([getStores, getGovs, getStoreStock, getDeliveryCompanies])
            .then(axios.spread((...responses) => {
                const storeResponse = responses[0];
                const govsResponse = responses[1];
                const productsResponse = responses[2];
                const deliveryCompaniesResponse = responses[3];
                setStores(storeResponse.data.stores);
                setGovs(govsResponse.data);
                setProducts(productsResponse.data.stock);
                setDeliveryCompanies(deliveryCompaniesResponse.data)
                formik.setFieldValue('order.deliveryCompany', deliveryCompaniesResponse.data[0])
            })).catch(e => {})
            .finally(() => {
                setLoading(true);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (govs !== null) {
            setActiveGov({
                gov: govs[0].id,
                delegations: govs[0].delegations
            })
        }
    }, [govs]);

    // const stores = apiCall(accessToken).get('/manager/check_user').then(r => console.log("r",r));
    return (
        loading ?
        <Grid container item rowSpacing={2} columnSpacing={2} xs={12}>
            <Grid container item xs={12}>
                <Typography color={"primary"} variant={"h6"}>
                    Nouveau Commande :
                </Typography>
            </Grid>
            {/*؛PickUp Section*/}

            <Grid container item xs={12}>
                <PickUp stores={stores} formik={formik} deliveryCompanies={deliveryCompanies} handleStoreChange={handleStoreChange}/>
            </Grid>

            {/*Specifications*/}
            <Grid container item xs={12}>
                <Specification formik={formik}/>
            </Grid>
            <Grid container item xs={12}>
                {clientList && !oldClient && <OldClient govs={govs} setOldClient={setOldClient} clientsList={clientList}
                                                        setActiveGov={setActiveGov} formik={formik}/>}
            </Grid>
            {/*Client Informations*/}
            <Grid container item xs={12}>
                <ClientInformation oldClient={oldClient} setClientList={setClientList} formik={formik} govs={govs}
                                   setActiveGov={setActiveGov} activeGov={activeGov} deleteOldClient={deleteOldClient} updateOldClient={updateOldClient}/>
            </Grid>

            {/*Discount Section*/}
            <Grid container item xs={12}>
                <DiscountSection formik={formik}/>
            </Grid>

            {/*Add Items*/}
            <Grid container item xs={12}>
                <Items formik={formik} itemsError={itemsError} currentQte={currentQte} products={products}
                       setCurrentQte={setCurrentQte} items={items} setItems={setItems} currentProduct={currentProduct}
                       setCurrentProduct={setCurrentProduct}/>
            </Grid>

            {/*Items List*/}
            <Grid container item xs={12}>
                {items &&
                    <Itemslist
                        formik={formik}
                        items={items}
                        setItems={setItems}
                        discount={formik.values.order.discount}
                        deliveryPrice={formik.values.order.deliveryCompany.public_price}
                    />
                }
            </Grid>


            <Grid container justifyContent={"center"} item md={12} xs={12}>
                <Stack direction="row" spacing={2}>
                    <Button size={"large"} variant={"contained"} color={"primary"}
                            onClick={formik.submitForm}>Ajouter</Button>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={addNew}
                                onChange={() => setAddNew((prev) => !prev)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={addNew ? "Enregistrer et créer nouveau commande" : "Enregistrer et retourner vers la liste des commandes"}
                    />
                </Stack>
            </Grid>
        </Grid> : <Loader/>

    )
}