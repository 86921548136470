import React, {useContext, useEffect, useState} from "react";
import {DashboardContext} from "../home";
import CustomPaper from "../../../component/common/custom_paper";
import Grid from "@mui/material/Grid";
import {DatePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import moment from "moment";
import Typography from "@mui/material/Typography";


export default function GainsStats({expenses}){

    const {orderOftheMonth} = useContext(DashboardContext);
    const [thisMonthOrders,setThisMonthOrders] = useState([]);
    const [returnThisMonth,setReturnThisMonth] = useState({ number : 0 , percentage : "0%"})
    const [value, setValue] = useState(moment());
    const [returnPrice,setReturnPrice] = useState(3);

    function calculateDeliveredOrdersPrice(){
        if(thisMonthOrders) {
            let somme = 0;
            thisMonthOrders.forEach((order,index) => {
                somme += parseInt((order.amount));
            })
            return somme;
        }
    }
    function filterData(){
        let tab = orderOftheMonth.allOrders
        let fDate = moment(value).startOf('month')
        let lDate = moment(value).endOf("month")
        let filtered = tab.filter(function (row) {
            let compareDate = moment(row.created_at)
            let comparison = moment(compareDate).isBetween(fDate, lDate) && row.deliveryStatus === 6 && row.phase === "dropOff"

            if (comparison) {
                return true
            } else {
                return false
            }
        })
        setThisMonthOrders(filtered);
    }
    // ReturnStatus[8,14,16,17,12]
    function returnThisMonths(data) {
        const returnStatus = [16,17,12];
        let fDate = moment(value).startOf('month')
        let lDate = moment(value).endOf("month")
            const monthOrder = data.filter((order) => {
                if (moment(moment(order.created_at)).isBetween(fDate, lDate) && order.phase === "dropOff"){
                    return order
                }
                else return false
            })

            const returnedOrder = monthOrder.filter((order) => {
                if (returnStatus.includes(order.deliveryStatus)){
                    return order
                }
                else return false
            }).length

            const percentage = monthOrder.length === 0 ? 0 : returnedOrder / monthOrder.length * 100

            setReturnThisMonth({
                percentage : Math.round(percentage * 100) / 100,
                number:  returnedOrder
            })
    }

    function compareDate(currentDate){
        let fDate = moment(value).startOf('month')
        let lDate = moment(value).endOf("month")
        return  moment(currentDate).isBetween(fDate, lDate)

    }
    function totalExpensesCurrentMonth(){

        let somme = 0;
        expenses.forEach((exp) => {
            if(compareDate(moment(exp.created_at))){
                somme += exp.value
            }
        });
        return somme;
    }

    function MoneyReceived(){

        let total = 0;
        thisMonthOrders.forEach((order) => {
            if(order.moneyReceived){
                total += order.amount - order.delivery_price
            }
        });
        return total;
    }

    function MoneyNotReceived(){
        let total = 0;
        thisMonthOrders.forEach((order) => {
            if(!order.moneyReceived){
                total += order.amount - order.delivery_price
            }
        });
        return total;
    }

    function calculateDeliveriesPrice() {
        let total = 0;

        thisMonthOrders.forEach((order) => {
            total += order.delivery_price
        })
        return total;
    }
    useEffect(() => {
        filterData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value]);

    function calculateNet(){
        return calculateDeliveredOrdersPrice() - calculateDeliveriesPrice() - totalExpensesCurrentMonth() - productsCost() - (returnThisMonth.number * returnPrice)
    }
    useEffect(() => {
        if(thisMonthOrders) returnThisMonths(orderOftheMonth.allOrders);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thisMonthOrders]);


    function productsCost() {
        let total = 0;
        thisMonthOrders.forEach((order) => {
            let subTotal = 0;
            if(order.items){
                order.items.forEach((item) => {
                    subTotal += (item.cost * item.quantity)
                })
            }
            total += subTotal;
        })

        return total.toFixed(3);
    }

    return(
        <Grid container item xs={12} spacing={2} alignItems="flex-start">
            <Grid container item xs={12}>
                <CustomPaper elevation={8} css={{minHeight : 123}}>
                    <Grid item xs={12} md={6} alignItems="center">
                        <DatePicker
                            fullWidth
                            views={['year', 'month']}
                            label="Year and Month"
                            minDate={moment("2021-01-01")}
                            maxDate={moment()}
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} helperText={null} />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="returnPrice"
                            value={returnPrice}
                            onChange={(e) => setReturnPrice(e.target.value)}
                        />
                    </Grid>
                </CustomPaper>
            </Grid>

            <Grid container item xs={12}>
                <CustomPaper elevation={8} css={{WebkitBorderBottomLeftRadius : 0 , WebkitBorderBottomRightRadius : 0,backgroundColor : t => t.palette.primary.main, color : "white"}}>
                    <Grid container item xs={4}>
                        <Typography>Titre</Typography>
                    </Grid>
                    <Grid container item xs={4} justifyContent="center">
                        <Typography>Nbr Colis</Typography>
                    </Grid>
                    <Grid container item xs={4} justifyContent="center">
                        <Typography>Valeur (DT)</Typography>
                    </Grid>
                </CustomPaper>
                {thisMonthOrders &&
                    <CustomPaper elevation={8}  css={{WebkitBorderTopLeftRadius : 0 , WebkitBorderTopRightRadius : 0}}>
                        <Grid container item xs={12} sx={{borderBottom : "1px solid red",paddingBottom : 4}} alignItems="center">
                            <Grid container item xs={4}>
                                <Typography fontWeight={600}>Total commande Livreé :</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center" >
                                <Typography>{thisMonthOrders.length}</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>{calculateDeliveredOrdersPrice()}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sx={{borderBottom : "1px solid red",paddingBottom : 4}} alignItems="center">
                            <Grid container item xs={4}>
                                <Typography fontWeight={600}>Total coût de livraison :</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center" >
                                <Typography>{thisMonthOrders.length}</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>{calculateDeliveriesPrice()}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sx={{borderBottom : "1px solid red",paddingBottom : 4}}>
                            <Grid container item xs={4}>
                                <Typography fontWeight={600}>Commande en retour :</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>{`${returnThisMonth.number} (${returnThisMonth.percentage}%)`}</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>{returnThisMonth.number * returnPrice}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sx={{borderBottom : "1px solid red",paddingBottom : 4}}>
                            <Grid container item xs={4}>
                                <Typography fontWeight={600}>Dépenses :</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>---</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>{totalExpensesCurrentMonth()}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sx={{borderBottom : "1px solid red",paddingBottom : 4}}>
                            <Grid container item xs={4}>
                                <Typography fontWeight={600}>Coût Produits:</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>---</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>{productsCost()}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} sx={{borderBottom : "1px solid red",paddingBottom : 4}}>
                            <Grid container item xs={4}>
                                <Typography fontWeight={600}>Net:</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>---</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>{calculateNet()}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} sx={{borderBottom : "1px solid red",paddingBottom : 4}}>
                            <Grid container item xs={4}>
                                <Typography fontWeight={600}>Argent reçu :</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>---</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>{MoneyReceived()}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sx={{paddingBottom : 2}}>
                            <Grid container item xs={4}>
                                <Typography fontWeight={600}>Argent Non-reçu :</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>---</Typography>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center">
                                <Typography>{MoneyNotReceived()}</Typography>
                            </Grid>
                        </Grid>
                    </CustomPaper>
                }
            </Grid>

        </Grid>
    )
}

