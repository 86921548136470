import * as React from 'react';
import {useContext} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Paper} from "@mui/material";
import Stack from "@mui/material/Stack";
import {calculateTotal, sousTotal} from "../../../helpers/functions";
import OrderCard from "./order_table/components/orderCard";
import EditIcon from '@mui/icons-material/Edit';
import {useHistory} from "react-router-dom";
import apiCall from "../../../api/axios_config";
import UserContext from "../../../context/userContext";
import AlertsContext from "../../../context/alertsContext";
import OrderContext from "../../../context/OrderContext";
import {routesName} from "../../../router/router";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const Item = styled(Grid)(({theme}) => ({
//     backgroundColor: theme.palette.primary.main,
//     ...theme.typography.body2,
//     padding: theme.spacing(4),
//     textAlign: 'center',
//     color: "#ffffff",
// }));
export default function SendToIntigo({order}) {
    const [open, setOpen] = React.useState(false);
    const {setSnackBar} = React.useContext(AlertsContext);
    const {accessToken} = useContext(UserContext);
    const {orders,setOrders} = useContext(OrderContext);
    const history = useHistory();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        history.push(routesName.orders.index.path)
    };

    const submitToIntigo = () => {
        apiCall(accessToken).post('/orders/submit_to_intigo',{
        // apiCall(accessToken).get('/test/error',{
            orders : [order.id]
        }).then(res => {
            if (res.status === 200) {
                setSnackBar({
                    status : true,
                    message : "Envoyée vers Intigo Avec Succées"
                });
                // setOrders(res.data.orders);
                const newList = orders.map((obj) => {
                    if(obj.id === order.id){
                        return res.data.orders[0];
                    } else {
                        return  obj;
                    }
                });

                setOrders(newList);
                handleClose();
                // history.push('/orders/list');
            }
        }).catch((e) => {setSnackBar({status : true , message : "Une erreur est survenue, veuillez réessayer plus tard",severity : "error"})});
    }

    return (
        <div>
            <Button
                onClick={handleClickOpen}
                endIcon={<DoubleArrowIcon/>}
            >
                <img alt="logoIntigo" loading="lazy" style={{height: 25}} src={'/intigo.png'}/>
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={"lg"}
                fullWidth
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Voulez vous vraiment submit to Intigo ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Grid container item xs={12} alignItems="flex-start" spacing={2}>
                            <Grid container item xs={12} md={6} spacing={2}>
                                <Grid container item xs={12}>
                                    <Typography color="primary">Information Client :</Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Stack spacing={2}>
                                        <Typography>Nom & Prénom : {order.client.fullName}</Typography>
                                        <Typography>Telephone : {order.client.phone}</Typography>
                                        <Typography>Telephone 2
                                            : {order.client.phoneTwo ? order.client.phoneTwo : "----"}</Typography>
                                        <Typography>Ville
                                            : {order.client.delegation.name} - {order.client.delegation.gov.name}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={1}>
                                <Grid container item xs={12}>
                                    <Typography color="primary">Detail de la commande :</Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Paper elevation={3} sx={{width: "100%", padding: 2}}>
                                        <Grid container item xs={12}>
                                            <Grid container item justifyContent="flex-start" xs={6}>
                                                Produit
                                            </Grid>
                                            <Grid container item justifyContent="center" xs={2}>
                                                Quantité
                                            </Grid>
                                            <Grid container item justifyContent="center" xs={2}>
                                                P.U
                                            </Grid>
                                            <Grid container item justifyContent="center" xs={2}>
                                                P.T
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                {
                                    order.items.map((item) => (
                                        <Grid container item xs={12}>
                                            <Paper elevation={3} sx={{width: "100%", padding: 2}}>
                                                <Grid container item xs={12}>
                                                    <Grid container item xs={6} justifyContent="flex-start">
                                                        {item.product.name}
                                                    </Grid>
                                                    <Grid container item xs={2} justifyContent="center">
                                                        {item.quantity}
                                                    </Grid>
                                                    <Grid container item xs={2} justifyContent="center">
                                                        {item.price}
                                                    </Grid>
                                                    <Grid container item xs={2} justifyContent="center">
                                                        {item.quantity * item.price}
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            <Grid container item xs={12} justifyContent="center" spacing={2}>

                                <Grid container item  xs={6} sm={3}>
                                    <OrderCard title={"Sous-Total"} content={sousTotal(order.items) + " DT"}/>
                                </Grid>
                                <Grid container item justifyContent="center" xs={6} sm={3}>
                                    <OrderCard title={"Remise"} content={order.discount + " %"}/>
                                </Grid>
                                <Grid container item justifyContent="center" xs={6} sm={3}>
                                    <OrderCard title={"Livraison"}
                                               content={order.free_delivery ? "0.000" : order.delivery_price + " DT"}/>
                                </Grid>
                                <Grid container item justifyContent="center" xs={6} sm={3}>
                                    <OrderCard title={"Total"}
                                               content={calculateTotal(order.items, order.discount, order.free_delivery ? 0 : order.delivery_price) + " DT"}/>
                                </Grid>


                            </Grid>

                        </Grid>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={() => history.push(`/orders/edit/${order.id}`)}
                        endIcon={<EditIcon/>}
                        variant="contained"
                        color="success"
                    >
                        Editer
                    </Button>
                    <Button
                        onClick={submitToIntigo}
                        endIcon={<DoubleArrowIcon/>}
                    >
                        <img alt="inti" style={{height: 25}} src={'/intigo.png'}/>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
