import React, {useContext, useEffect, useState} from "react";
import {Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import UserContext from "../../../../context/userContext";
import apiCall from "../../../../api/axios_config";
import moment from "moment";
import {calculateDiscount, calculateTotal, sousTotal} from "../../../../helpers/functions";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";

Font.register({ family: 'Roboto', src: "/fonts/Roboto/Roboto-Regular.ttf" });
Font.register({ family: 'Roboto-Bold', src: "/fonts/Roboto/Roboto-Black.ttf" });
Font.register({ family: 'Cairo', src: "/fonts/Cairo-VariableFont_wght.ttf" });
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        fontFamily : "Roboto-Bold",
        fontWeight : 900
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        border: "1px solid red",
        borderRadius: "15px",

    },
    header: {
        width: "100%",
        height: '160px',
        display: "flex",
        flexDirection: "row",
    },
    leftHeader: {
        height: "80%",
        display: "flex",
        flexGrow: 1,
        alignItems : "center",
        justifyContent : "center"
    },
    logo: {
        height: 120,
        width: 100
    },
    rightHeader: {
        display: "flex",
        flexGrow: 2,
        height: "80%",
        alignItems : "center",
        justifyContent : "center"
    },
    p : {
        fontSize : 10,
        fontFamily : "Roboto",
        marginTop : 5
    },

    pRed : {
        color : "red",
        fontSize : 10,
        fontFamily : "Roboto",
        marginTop : 5
    }
});

export default function OrderPDF() {

    const [orders,setOrders] = useState({});
    const [loading, setLoading] = useState(true);
    const {accessToken} = useContext(UserContext);
    const {id} = useParams();
    const history = useHistory();
    useEffect(() => {
        apiCall(accessToken).get(`/orders/get/${id}`)
            .then((res) => setOrders(res.data.order))
            .catch((e) => {
                if(e.response.status === 404) history.push('/404')
            })
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <div style={{width : "100%",height:"100vh"}}>
            <PDFViewer width={"100%"} height={"100%"}>
                <Document>
                    {!loading &&

                        orders.map((order) => (
                        <Page size="A4" style={styles.page}>
                                <View style={styles.section}>
                                    <View style={styles.header}>
                                        <View style={styles.leftHeader}>
                                            <Image style={styles.logo} src="/logo.png"/>
                                        </View>
                                        <View style={styles.rightHeader}>
                                            <Text style={{textTransform :"uppercase", fontSize : 28,alignSelf:"flex-end"}}> Bon de Commande</Text>
                                            <Text style={{textTransform :"uppercase", fontSize : 14,alignSelf:"flex-end"}}> REF : {order.commandeNumber} | {moment(order.created_at).format('Y')}</Text>
                                            <Text style={{ fontSize : 14,alignSelf:"flex-end"}}> Date : {moment(order.created_at).format('DD-MM-Y')}</Text>
                                            <Text style={{ fontSize : 14,alignSelf:"flex-end"}}> Date de PickUp : {moment(order.deliveryDate).format('DD-MM-Y')}</Text>
                                        </View>
                                        <View style={{display : "flex",flexGrow : 2}}>

                                        </View>
                                    </View>
                                    <View style={{display: "flex",width:"100%",padding : 10, border : '1px solid red',borderRadius : 10,alignSelf :"center",flexDirection : "row"}}>
                                        <View style={{fontFamily : "Cairo",fontSize : 10,color : "red"}}>
                                            <Text>Client :</Text>
                                            <Text>Adress :</Text>
                                            <Text>Gouvernorat:</Text>
                                            <Text>Ville:</Text>
                                            <Text>Téléphone:</Text>
                                        </View>
                                        <View style={{ marginLeft : 20,fontFamily : "Cairo",fontSize : 10}}>
                                            <Text>{order.client.fullName}</Text>
                                            <Text>{order.client.address}</Text>
                                            <Text>{order.client.delegation.gov.name}</Text>
                                            <Text>{order.client.delegation.name}</Text>
                                            <Text>{order.client.phone}  {order.client.phoneTwo && `- ${order.client.phoneTwo}`}</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop : 10}}>
                                        <Text style={{fontSize : 14}}>Detail de la commande :</Text>
                                    </View>
                                    <View style={{
                                        marginTop : 10,
                                        display:"flex",
                                        flexDirection: "row",
                                        backgroundColor : 'red',
                                        borderRadius : 10,
                                        padding : 3,
                                        fontFamily : "Roboto",
                                        fontSize : 10,
                                        color : "white"
                                    }}>
                                        <View style={{display:"flex",flex: '0 0 50%',marginLeft : 5}}>
                                            <Text>Articles</Text>
                                        </View>
                                        <View style={{display:"flex",flex: '0 0 10%', alignItems : "center"}}>
                                            <Text>QTE</Text>
                                        </View>
                                        <View style={{display:"flex",flex :"0 0 20%", alignItems : "center"}}>
                                            <Text>Prix unitaire</Text>
                                        </View>
                                        <View style={{display:"flex",flex : "0 0 20%", alignItems : "center"}}>
                                            <Text>sous-Total</Text>
                                        </View>
                                    </View>

                                    {order.items.map((item) => (
                                        <View style={{
                                            marginTop : 2,
                                            display:"flex",
                                            flexDirection: "row",
                                            borderRadius : 10,
                                            border : "1px solid red",
                                            padding : 3,
                                            fontFamily : "Roboto",
                                            fontSize : 9,
                                            alignItems : "center"
                                        }}>
                                            <View style={{display:"flex",flex: '0 0 50%',marginLeft : 5}}>
                                                <Text style={{fontFamily : "Cairo"}}>{item.product.name}</Text>
                                            </View>
                                            <View style={{display:"flex",flex : "0 0 10%", alignItems : "center"}}>
                                                <Text>{item.quantity}</Text>
                                            </View>
                                            <View style={{display:"flex",flex : "0 0 20%", alignItems : "center"}}>
                                                <Text>{item.price.toFixed(3)}</Text>
                                            </View>
                                            <View style={{display:"flex",flex : "0 0 20%", alignItems : "center"}}>
                                                <Text>{(item.quantity * item.price).toFixed(3)}</Text>
                                            </View>
                                        </View>
                                    ))}
                                    <View style={{display : "flex", padding : 5 ,justifyContent : "flex-end",flexDirection : "row",fontFamily : "Cairo",fontSize : 10}}>
                                        <View
                                            style={{display : "flex",flex : "0 0 20%"}}
                                        >
                                            <Text
                                                style={{
                                                    padding : 2,
                                                    fontWeight : 500
                                                }}
                                            >Sous-Total:</Text>
                                            <Text
                                                style={{
                                                    padding : 2,
                                                    fontWeight : 500
                                                }}
                                            >Remise ({order.discount} %):</Text>
                                            <Text
                                                style={{
                                                    padding : 2,
                                                    fontWeight : 500
                                                }}
                                            >Prix Livraison:</Text>
                                            <Text
                                                style={{
                                                    padding : 2,
                                                    fontWeight : 500
                                                }}
                                            >Total:</Text>
                                        </View>
                                        <View
                                            style={{display : "flex",flex : "0 0 10%",alignItems:"flex-end"}}
                                        >
                                            <Text
                                                style={{
                                                    padding : 2,
                                                    fontWeight : 500
                                                }}
                                            >
                                                {sousTotal(order.items).toFixed(3)}
                                            </Text>
                                            <Text
                                                style={{
                                                    padding : 2,
                                                    fontWeight : 500
                                                }}
                                            >
                                                {calculateDiscount(order).toFixed(3)}
                                            </Text>
                                            <Text
                                                style={{
                                                    padding : 2,
                                                    fontWeight : 500
                                                }}
                                            >{(order.free_delivery ? 0 : order.delivery_price).toFixed(3)}</Text>
                                            <Text
                                                style={{
                                                    color : "white",
                                                    backgroundColor : "red",
                                                    padding : 2,
                                                    fontWeight : 500
                                                }}
                                            >
                                                {calculateTotal(order.items,order.discount,order.free_delivery ? 0 : order.delivery_price).toFixed(3)} DT
                                            </Text>
                                        </View>
                                    </View>
                                </View>

                        </Page>
                    ))
                    }
                </Document>
            </PDFViewer>
        </div>
    )
}